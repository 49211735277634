<script setup>
const props = defineProps(["withregister", "wholewords"]);
const emit = defineEmits(["update:withregister", "update:wholewords", "open"]);

const searchWholeWord = ref(false);
const searchWithReg = ref(false);

function toggleWithRegister() {
  searchWithReg.value = !searchWithReg.value;
  emit("update:withregister", searchWithReg.value);
}

function toggleWholeWord() {
  searchWholeWord.value = !searchWholeWord.value;
  emit("update:wholewords", searchWholeWord.value);
}
</script>
<template>
  <v-menu origins="top, right">
    <template #activator="{ on }">
      <v-btn v-on:click="on" class="menu-icon">
        <img src="/icons/menu-dots.svg" alt="menu" />
      </v-btn>
    </template>

    <div class="menu-content-wrapper">
      <div class="menu-item" @click="toggleWithRegister()">
        <v-switch v-model="searchWithReg"></v-switch>
        <div class="ml-2">{{ transl("Search with register") }}</div>
      </div>
      <div class="menu-item" @click.stop="toggleWholeWord()">
        <v-switch v-model="searchWholeWord"></v-switch>
        <div class="ml-2">{{ transl("Search whole word") }}</div>
      </div>
    </div>
  </v-menu>
</template>
<style scoped>
.menu-content-wrapper {
  width: 320px;
  font-weight: normal;
}
.menu-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.menu-icon:active {
  background-color: rgba(128, 128, 128, 0.298);
}

.menu-item {
  display: flex;
  align-items: center;
  height: 55px;
  padding: 0px 16px;
  color: var(--btn-menu-text);
  line-height: 23px;
  font-size: 13px;
  cursor: pointer;
  user-select: none;
}
@media (hover: hover) {
  .menu-icon:hover {
    background-color: rgba(128, 128, 128, 0.036);
    cursor: pointer;
  }
}
@media (hover: none) {
  .item:active {
    background-color: rgba(128, 128, 128, 0.036);
    cursor: pointer;
  }
}
</style>
